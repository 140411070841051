import React from "react";
import { MyHome } from "./Style";
import HeroComponent from "./Components/HeroComponent/HeroComponent";
import WhatWeDoComponent from "./Components/WhatWeDoComponent/WhatWeDoComponent";
import AboutUsComponent from "./Components/AboutUsComponent/AboutUsComponent";
import WhatClientsSay from "./Components/WhatClientsSay/WhatClientsSay";
import WhyWorkComponent from "./Components/WhyWorkComponent/WhyWorkComponent";
import LocationComponent from "./Components/LocationComponent/LocationComponent";
import BookCallComponent from "./Components/BookCallComponent/BookCallComponent";
import DropMessage from "./Components/DropMessage/DropMessage";
import HowItWorks from "./Components/HowItWorks/HowItWorks";
import NumbersSection from "./Components/NumbersSection/NumbersSection";
import CaseStudies from "./Components/CaseStudies/CaseStudies";

const Home = () => {
  return (
    <MyHome>
      <HeroComponent />
      <WhatWeDoComponent />
      <CaseStudies />
      <AboutUsComponent />
      <WhatClientsSay />
      <WhyWorkComponent />
      <NumbersSection />
      <HowItWorks />
      <LocationComponent />
      <BookCallComponent />
      <DropMessage />
    </MyHome>
  );
};

export default Home;

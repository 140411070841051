import { styled, Grid } from "@mui/material";

export const Image = styled("img")(({ theme, isAlignRight }) => ({
  margin: "0 auto",
  width: "100%",

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, isAlignRight ? 0 : 4, 0, isAlignRight ? 4:0),
  },

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, isAlignRight ? 0 : 8, 0, isAlignRight ? 8:0),
  }
}));

export const GridWrapper = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(2),

  [theme.breakpoints.up("md")]: {
    gap: 0,
  }
}));
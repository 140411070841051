import React from "react";
import { Container, Stack, Typography } from "@mui/material";
import { InnerSlickWrapper, MainBox } from "./Style";
import { StarIcon } from "../../../../Shared/assets";
import Slider from "react-slick";
import { ArrowLeft, ArrowRight } from "../../../../Components";

const slidesArray = [
  {
    desc: "Outstanding work! The software development team delivered our project on time and within budget. Their expertise and dedication exceeded our expectations. Highly recommended!",
    author: "Artur Rapacz",
    profession: "CEO of PAIE ltd",
  },
  {
    desc: "Top-notch service! The software developers were responsive, communicative, and tackled complex challenges effortlessly. We're thrilled with the final product and will definitely work with them again.",
    author: "Adam S.",
    profession: "Startup Founder",
  },
  {
    desc: "Exceptional results! The software development company demonstrated a deep understanding of our needs and delivered a user-friendly, high-performance application. We couldn't be happier with the outcome.",
    author: "Jakub Kowalczyk",
    profession: "Head of Sales, H2B Group",
  },
];

const WhatClientsSay = () => {
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <MainBox>
      <Container>
        <Typography component="h2" variant="h2">
          What our clients say
        </Typography>
        <Slider {...settings}>
          {slidesArray?.map((item, index) => (
            <InnerSlickWrapper key={index}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
                <img src={StarIcon} alt="star"/>
              </Stack>
              <Typography component="p" variant="body2">
                {item.desc}
              </Typography>
              <Typography component="h6" variant="body1" style={{marginTop: "auto"}}>
                {item.author}
              </Typography>
              <Typography component="span" variant="body2">
                {item.profession}
              </Typography>
            </InnerSlickWrapper>
          ))}
        </Slider>
      </Container>
    </MainBox>
  );
};

export default WhatClientsSay;

import { styled } from "@mui/system";
import { Button } from "@mui/material";

export const MyButton = styled(Button)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 400,
  color: theme.palette.white,
  backgroundColor: theme.palette.darkBlue,
  borderRadius: "64px",
  padding: theme.spacing(2, 4),
  textTransform: "capitalize",
  transition: "all 0.3s",

  "&:hover": {
    backgroundColor: theme.palette.darkBlue,
    opacity: 0.8,
  },

  "&:disabled": {
    backgroundColor: theme.palette.lightGray,
    color: theme.palette.gray,
  }
}));

import {Box, styled} from "@mui/material";
export const Arrow = styled(Box)(({theme}) => ({
  position: "absolute",
  top: "100%",
  left: "calc(100% - 18px)",
  transform: "translate(-100%, 0)",
  width: "56px",
  height: "56px",
  backgroundColor: theme.palette.darkBlue,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100%",

  "&::before": {
    display: "none",
  },

  "&:hover": {
    backgroundColor: theme.palette.darkBlue,
    opacity: 0.8,
  },

  "&.prev": {
    left: "calc(100% - 18px - 56px - 16px)",
  },

  "& img": {
    display: "block",
    margin: "0 auto",
    width: "28px",
    height: "20px",
    objectFit: "contain",
  },
}));
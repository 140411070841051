import { Arrow } from "./Style";
import SlickArrowNext from "../../Assets/SlickArrowNext.svg";

const ArrowRight = (props) => {
  const {className, onClick} = props;
  return (
    <Arrow className={className} onClick={onClick}>
      <img src={SlickArrowNext} alt="next"/>
    </Arrow>
  );
};

export default ArrowRight;
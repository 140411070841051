import { styled } from "@mui/material";

export const WhyWorkSection = styled("section")(({ theme }) => ({
  "& h2": {
    marginBottom: `${theme.spacing(8)} !important`,
  },

  "& .item": {
    color: theme.palette.gray,

    "& img": {
      width: "56px",
      height: "56px",
      objectFit: "contain",
    },
  },

  [theme.breakpoints.up("md")]: {
    "& .item img": {
      width: "74px",
      height: "74px",
    },
  },
}));

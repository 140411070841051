import { Stack, styled } from "@mui/material";

export const ControllerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const Message = styled("span")(({ theme }) => ({
  color: theme.palette.success,
}));

export const ErrorMessage = styled(Message)(({ theme }) => ({
  color: theme.palette.failure,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(-0.5),
}));

export const MainBox = styled("section")(({ theme }) => ({
  backgroundColor: theme.palette.lightPurpleBackground,

  "& .content > div": {
    width: "100%",
    maxWidth: "500px",
    color: theme.palette.gray,

    "&:last-of-type": {
      display: "none",
    },
  },

  "form": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    alignItems: "flex-end",

    "> div": {
      width: "100%",
    },

    "& button": {
      width: "fit-content",
    }
  },

  "input, textarea": {
    width: "100%",
    padding: theme.spacing(1, 2),
    borderRadius: "10px",
    border: `1px solid ${theme.palette.gray}`,
    backgroundColor: "transparent",
    resize: "none",
    fontSize: "16px",

    "&:focus": {
      outline: "none",
    },
  },

  "& h4": {
    width: "fit-content",
    paddingBottom: theme.spacing(1),
    color: theme.palette.gray,
    position: "relative",

    "&::after": {
      content: '""',
      position: "absolute",
      top: "100%",
      left: "0",
      width: "50%",
      height: "4px",
      borderRadius: "10px",
      backgroundColor: theme.palette.gray,
    },
  },

  [theme.breakpoints.up("md")]: {
    "& h2": {
      marginLeft: 0,
    },

    "& .content > div": {
      maxWidth: "600px",

      "&:last-of-type": {
        display: "flex",
        width: "fit-content",
      },
    },
  },
}));

export const Socials = styled(Stack)(({ theme }) => ({
  "& a": {
    width: "48px",
    height: "48px",
    borderRadius: "100%",
    backgroundColor: "#D9D9D9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create(["all"]),

    "&:hover": {
      transform: "scale(1.1)",
    },

    "& img": {
      width: "20px",
      height: "20px",
      objectFit: "contain",
    },
  },
}));

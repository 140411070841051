import { styled, Box } from "@mui/material";

export const HeroSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 0),
  overflow: "hidden",
  position: "relative",
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.up("sm")]: {
    minHeight: "500px",
  },

  [theme.breakpoints.up("md")]: {
    minHeight: "600px",
  },
}));

export const HeroImageContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  top: 0,
  left: "50%",
  transform: "translate(-50%, 0)",
  width: "300px",
  zIndex: -1,
  marginTop: theme.spacing(5),

  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },

  "& div": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "350%",
    height: "350%",
    backgroundColor: "#F2F4FF",
    borderRadius: "100%",
    zIndex: -1,

    "&:nth-of-type(2)": {
      width: "250%",
      height: "250%",
      backgroundColor: theme.palette.lightPurpleBackground,
    },

    "&:nth-of-type(3)": {
      width: "150%",
      height: "150%",
      backgroundColor: "#FAFBFF",
    },
  },

  [theme.breakpoints.up("sm")]: {
    position: "absolute",
    top: "20%",
    left: "80%",
  },

  [theme.breakpoints.up("md")]: {
    top: "15%",
    left: "75%",
    width: "350px",
  },

  [theme.breakpoints.up("lg")]: {
    top: "10%",
    width: "400px",
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  maxWidth: "initial",

  "& h1": {
    color: theme.palette.gray,
    marginBottom: theme.spacing(1),
  },

  "& p": {
    color: theme.palette.gray,
    marginBottom: theme.spacing(2),
  },

  [theme.breakpoints.up("sm")]: {
    maxWidth: "350px",

    "& h1": {
      marginBottom: theme.spacing(2),
    },

    "& p": {
      marginBottom: theme.spacing(5),
    },
  },

  [theme.breakpoints.up("md")]: {
    maxWidth: "500px",

    "& p": {
      marginBottom: theme.spacing(7),
    },
  },
}));

import React from "react";
import { WhyWorkSection } from "./Style";
import { Container, Grid, Stack, Typography } from "@mui/material";
import {
  ClientFocus,
  CostReduction,
  FastTeamForming,
  Flexibility,
  LongTermRelation,
  VarietyOfTech,
} from "../../../../Shared/assets";

const contentArray = [
  {
    src: FastTeamForming,
    title: "Fast team forming",
    desc: "Rapid team formation enables swift project execution. Our streamlined processes and extensive network of experts ensure quick response times, seamless integration, and agile collaboration. We can start a project within less than a week since first contact.",
  },
  {
    src: CostReduction,
    title: "Cost Reduction",
    desc: "Through efficient resource allocation and streamlined processes, we optimize project expenses, ensuring high-quality results within budget. Relying on us can be up to 60% cheaper than in-house software development in the U.S",
  },
  {
    src: ClientFocus,
    title: "Client Focus",
    desc: "We prioritize your needs and offer comprehensive solutions. Our software, coupled with a full product discovery process, ensures a perfect fit for your requirements. Experience our client-focused approach and achieve tailored solutions that exceed your expectations.",
  },
  {
    src: VarietyOfTech,
    title: "Variety of technology",
    desc: "It doesn’t matter if you need a website, mobile app or sophisticated machine learning algorithm. Our specialists have many years on-hand experience with almost every existing technology stack.",
  },
  {
    src: LongTermRelation,
    title: "Long-term relations",
    desc: "Building long-term relationships is at the core of our values. We prioritize nurturing enduring partnerships with our clients, focusing on their long-term success",
  },
  {
    src: Flexibility,
    title: "Flexibility",
    desc: "We adapt quickly to changing needs, ensuring seamless adjustments throughout the project. Experience the benefits of our nimble mindset, allowing us to deliver optimal results in dynamic environments",
  },
];

const WhyWorkComponent = () => {
  return (
    <WhyWorkSection>
      <Container>
        <Typography component="h2" variant="h2">
          Why work with us
        </Typography>
        <Grid container spacing={{ xs: 7, lg: 10 }}>
          {contentArray?.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Stack
                direction={{ xs: "row", sm: "column" }}
                alignItems="flex-start"
                spacing={3}
                className="item"
              >
                <img src={item.src} alt={item.title} />

                <Stack spacing={2}>
                  <Typography component="h4" variant="h4">
                    {item.title}
                  </Typography>
                  <Typography component="p" variant="body1">
                    {item.desc}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </WhyWorkSection>
  );
};

export default WhyWorkComponent;

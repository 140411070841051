import {Box, styled} from "@mui/material";

export const MainBox = styled("section")(({theme}) => ({
  backgroundColor: theme.palette.white,
  paddingBottom: `${theme.spacing(15)} !important`,


  ".slick-track": {
    display: "flex",

    ".slick-slide": {
      height: "auto",

    },
  },

  "& .slick-slide > div": {
    height: "100%",
    borderRadius: "15px",
    backgroundColor: theme.palette.white,
    padding: theme.spacing(4, 2),
    margin: theme.spacing(0, 1),
    color: theme.palette.gray,
    textAlign: "center",

    "& p": {
      margin: theme.spacing(3, 0, 4),
    },

    "& h6": {
      fontWeight: 600,
    },

    "& span": {
      color: theme.palette.lightGray,
    },
  },

  [theme.breakpoints.up("sm")]: {
    backgroundColor: theme.palette.lightPurpleBackground,

  },

  [theme.breakpoints.up("lg")]: {
    "& .slick-slide > div": {
      margin: theme.spacing(0, 3),
    },
  },
}));

export const InnerSlickWrapper = styled(Box)(() => ({
  height: "100%",
  display: "flex !important",
  flexDirection: "column",
}));
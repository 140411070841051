import {Grid} from "@mui/material";
import {TextTile} from "../index";
import { Image, GridWrapper } from "./Style";
const TextWithImage = ({title, description, image, textAlign}) => {
  const order = textAlign === 'right';

  return (
      <GridWrapper
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={image ? 6 : 12} order={{xs: 0, md: Number(order) }}>
          <TextTile
            align={image ? textAlign : "center"}
            description={description}
            title={title}
          />
        </Grid>
        {image && <Grid container item xs={12} md={6} style={{padding: 0}} justifyContent={order ? "flex-end" : "flex-start"}>
          <Image isAlignRight={!order} src={image} alt="section image"/>
        </Grid>}
      </GridWrapper>
  );
}

export default TextWithImage;
import React from "react";
import { MainBox } from "./Style";
import { Container, Stack, Typography } from "@mui/material";
import { BookCall } from "../../../../Shared/assets";
import { MainButton } from "../../../../Components/Common";

const BookCallComponent = () => {
  return (
    <MainBox>
      <Container>
        <Typography component="h2" variant="h2">
          Book a call to learn more
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 4 }}
          justifyContent="flex-end"
          alignItems="center"
        >
          <img src={BookCall} alt="book call" />
          <div>
            <Typography component="p" variant="body1">
              Let’s set up a call and start working together
            </Typography>
            <MainButton>Book a call</MainButton>
          </div>
        </Stack>
      </Container>
    </MainBox>
  );
};

export default BookCallComponent;

import React from "react";
import { Container, Typography } from "@mui/material";
import { Content, HeroImageContainer, HeroSection } from "./Style";
import { HeroImage } from "../../../../Shared/assets";
import { MainButton } from "../../../../Components/Common";

const HeroComponent = () => (
  <HeroSection>
    <Container>
      <Content>
        <Typography component="h1" variant="h1">
          Code21
        </Typography>
        <Typography component="p" variant="body1">
          Transforming Ideas into Digital Realities.
        </Typography>
        <a href="#contactUs">
          <MainButton>Contact Us</MainButton>
        </a>
      </Content>
      <HeroImageContainer>
        <img src={HeroImage} alt="hero"/>
        <div></div>
        <div></div>
        <div></div>
      </HeroImageContainer>
    </Container>
  </HeroSection>
);

export default HeroComponent;

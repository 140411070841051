import React from "react";
import { MainBox, ImageContainer } from "./Style";
import { Container, Grid, Stack, Typography } from "@mui/material";
import {
  DocumentCheckIcon,
  DocumentIcon,
  FrameworkIcon,
  OrderIcon,
  PhoneIcon,
  RocketIcon,
} from "../../../../Shared/assets";

const contentArray = [
  {
    src: PhoneIcon,
    title: "Initial call",
  },
  {
    src: DocumentIcon,
    title: "NDA",
  },
  {
    src: DocumentCheckIcon,
    title: "Detailed Requirements",
  },
  {
    src: FrameworkIcon,
    title: "Framework Agreement",
  },
  {
    src: OrderIcon,
    title: "Individual order",
  },
  {
    src: RocketIcon,
    title: "Start of the project",
  },
];

const HowItWorks = () => {
  return (
    <MainBox>
      <Container>
        <Typography component="h2" variant="h2">
          How it works
        </Typography>
        <Typography component="p" variant="body1">
          We believe in easy and well described process, that results in clarity
          and safety for both sites. Please see it described below.
        </Typography>
        <Grid container spacing={5}>
          {contentArray?.map((item, index) => (
            <Grid key={index} item xs={6} sm={4} lg={2}>
              <Stack alignItems="center" spacing={3}>
                <ImageContainer>
                  <img src={item.src} alt={item.title} />
                </ImageContainer>
                <Typography component="p" variant="body1">
                  {item.title}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MainBox>
  );
};

export default HowItWorks;

import { styled } from "@mui/material";

export const WhatWeDoSection = styled("section")(({ theme }) => ({
  "& > p": {
    display: "none",
    color: theme.palette.gray,
    textAlign: "center",
    maxWidth: "800px",
    margin: theme.spacing(0, "auto", 5),
  },

  "& .singleItem": {
    height: "100%",
    backgroundColor: theme.palette.white,
    borderRadius: "15px",
    padding: theme.spacing(4, 2),
    boxShadow: theme.customShadows.whatWeDoItem,
    transition: "all 0.3s",
    cursor: "pointer",

    "&:hover": {
      transform: "scale(1.05)",
    },

    "& img": {
      display: "block",
      width: "175px",
      height: "140px",
      objectFit: "contain",
      margin: theme.spacing(0, "auto"),
    },

    "& h4": {
      marginBottom: theme.spacing(2),
      textTransform: "capitalize",
      textAlign: "center",
      color: theme.palette.gray,
    },

    "& p": {
      textAlign: "justify",
      color: theme.palette.gray,
    },
  },

  [theme.breakpoints.up("sm")]: {
    "& .singleItem h4, & .singleItem p": {
      textAlign: "justify",
    },
  },

  [theme.breakpoints.up("lg")]: {
    "& > p": {
      display: "block",
    },

    "& .singleItem": {
      minHeight: "375px",

      "& h4": {
        textAlign: "center",
      },

      "& p": {
        textAlign: "justify",
        fontSize: "14px",
      },
    },
  },
}));

import React from "react";
import { MainBox } from "./Style";
import { Container, Grid, Typography } from "@mui/material";

const NumbersSection = () => {
  return (
    <MainBox>
      <Container>
        <Typography component="h2" variant="h2">
          Numbers
        </Typography>
        <Typography component="p" variant="body1">
          We believe in transparency and letting the numbers speak for
          themselves. Here are some key figures that highlight Code21 growth,
          expertise, and impact:
        </Typography>
        <Grid container spacing={7}>
          <Grid item xs={12} sm={6} md={4}>
            <div className="item">
              <Typography component="h3" variant="h2">
                30%
              </Typography>
              <Typography component="p" variant="body1">
                Average cost reduction for clients (compared with in-house
                development)
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="item">
              <Typography component="h3" variant="h2">
                80%
              </Typography>
              <Typography component="p" variant="body1">
                out of our contractors stays on our contractors longer than 6
                months
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="item">
              <Typography component="h3" variant="h2">
                14
              </Typography>
              <Typography component="p" variant="body1">
                days in average from initial call to signed individual order
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="item">
              <Typography component="h3" variant="h2">
                3
              </Typography>
              <Typography component="p" variant="body1">
                years of experience
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="item">
              <Typography component="h3" variant="h2">
                120
              </Typography>
              <Typography component="p" variant="body1">
                contractors in our database
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </MainBox>
  );
};

export default NumbersSection;

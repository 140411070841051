import React from "react";
import { MainBox, Item, ProjectVisual } from "./Style";
import { Container, Stack, Typography } from "@mui/material";
import {
  Scada1,
} from "../../../../Shared/assets";
import { MainButton } from "../../../../Components/Common";
import { useNavigate } from "react-router-dom";

const contentArray = [
  {
    src: ProjectVisual,
    title: "SCADA System",
    subTitle: "Electrum S.A",
    desc: "Implementation of a Supervisory Control and Data Acquisition (SCADA) system developed by CODE21 for Electrum Induce S.A to monitor and predict data related to the performance of large-scale photovoltaic (PV) plants. Leveraging the power of React.JS, the system provides real-time monitoring, data visualization, and predictive analytics, empowering Electrum Induce S.A to optimize PV plant efficiency, identify potential issues, and make data-driven decisions to enhance overall performance.",
    image: Scada1,
    pageLink: '/case-studies/scada',
  },
];

const CaseStudies = () => {
  const navigate = useNavigate();
  const handleRedirect = (path) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    navigate(path);
  }

  return (
    <MainBox id="caseStudies">
      <Container>
        <Typography component="h2" variant="h2">
          Selected case studies
        </Typography>
        <Stack spacing={10}>
          {contentArray?.map((item, index) => (
            <Item
              key={index}
              direction={
                index === 1
                  ? { xs: "column", md: "row-reverse" }
                  : { xs: "column", md: "row" }
              }
              alignItems="center"
              spacing={{ xs: 4, md: 12.5 }}
              className={index === 1 && "reversedItem"}
            >
              <ProjectVisual>
                <img src={item.image} alt="project visual" />
              </ProjectVisual>

              <div>
                <Typography component="h3" variant="h3">
                  {item.title}
                </Typography>
                <Typography component="h4" variant="h4">
                  {item.subTitle}
                </Typography>
                <Typography component="p" variant="p">
                  {item.desc}
                </Typography>

                <MainButton onClick={() => handleRedirect(item.pageLink)}>More about the project</MainButton>
              </div>
            </Item>
          ))}
        </Stack>
      </Container>
    </MainBox>
  );
};

export default CaseStudies;

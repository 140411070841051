import {Scada1, Scada2, Scada3, Scada4, Scada5, Scada6, Scada7, Scada8, WindTurbine} from "./../../../Shared/assets";

export default {
  title: "Electrum Incude S.A.",
  description: "Enhancing Photovoltaic Plant Performance through CODE21's SCADA System in React.JS: A Case Study for Electrum Induce S.A",
  carousel: [Scada1, Scada2, Scada3, Scada4],
  sections: [
    {
      title: "Abstract",
      description: "This case study explores the successful implementation of a Supervisory Control and Data Acquisition (SCADA) system developed by CODE21 for Electrum Induce S.A to monitor and predict data related to the performance of large-scale photovoltaic (PV) plants. Leveraging the power of React.JS, the system provides real-time monitoring, data visualization, and predictive analytics, empowering Electrum Induce S.A to optimize PV plant efficiency, identify potential issues, and make data-driven decisions to enhance overall performance.",
      image: Scada5,
    },
    {
      title: "Introduction",
      description: "Electrum Induce S.A is a leading energy company that specializes in developing large-scale photovoltaic plants. With a growing portfolio of solar assets, they sought a comprehensive SCADA system to monitor and analyze the performance of their PV plants in real-time. CODE21, a renowned software development company, was tasked with creating a cutting-edge SCADA system using React.JS to fulfill Electrum Induce S.A's requirements.",
      image: Scada6
    },
    {
      title: "Client requirements",
      tiles: [
        {
          title: "Real-time monitoring",
          description: "Continuous monitoring of key parameters such as solar irradiance, panel temperature, voltage, current, and power output."
        },
        {
          title: "Data visualization",
          description: "Intuitive graphical representation of data to help operators quickly interpret complex information."
        },
        {
          title: "Performance prediction",
          description: "Implementation of predictive analytics to forecast potential plant performance based on historical data and weather forecasts."
        },
        {
          title: "Alarms and notifications",
          description: "Instantaneous alerts for anomalies, faults, and underperforming components to minimize downtime and optimize plant performance."
        },
        {
          title: "User-friendly interface",
          description: "An easy-to-use interface that allows non-technical staff to access, understand, and utilize the system efficiently."
        }
      ]
    },
    {
      title: "Development and Implementation",
      description: "CODE21 developed the SCADA system using React.JS due to its versatility, high-performance capabilities, and ability to handle real-time data. The development process followed an agile methodology to ensure continuous feedback and adaptability to evolving requirements. The development team integrated the SCADA system with Electrum Induce S.A's PV plant data acquisition infrastructure, enabling real-time data streams to be sent securely to the web application.",
      "image": Scada7
    },
    {
      title: "Key Features of the SCADA System",
      tiles: [
        {
          title: "Real-time Monitoring",
          description: "The SCADA system provides a live dashboard displaying crucial parameters, graphs, and KPIs. This real-time monitoring feature enables operators to detect irregularities promptly and respond to issues in real-time."
        },
        {
          title: "Data Visualization",
          description: "CODE21 designed interactive and visually engaging charts, graphs, and heat maps to represent historical and real-time data. The visualizations facilitate data analysis and help operators identify trends and patterns effortlessly."
        },
        {
          title: "Performance Prediction",
          description: "Leveraging historical data and advanced predictive algorithms, the SCADA system forecasts potential plant performance under different scenarios. This feature empowers Electrum Induce S.A to anticipate challenges and take proactive measures to optimize energy production."
        },
        {
          title: "Alarms and Notifications",
          description: "The SCADA system is equipped with customizable alarms and notifications that alert operators when any critical parameters deviate from expected values. Immediate alerts enable timely actions to prevent possible plant inefficiencies or failures."
        },
        {
          title: "User-Friendly Interface",
          description: "CODE21 prioritized creating an intuitive and user-friendly interface, allowing personnel with varying levels of technical expertise to access and interact with the SCADA system efficiently."
        }
      ]
    },
    {
      title: "Results and Benefits",
      description: "Since the deployment of CODE21's SCADA system, Electrum Induce S.A has experienced significant improvements in PV plant management and performance optimization.",
      "image": Scada8
    },
    {
      title: "Key Benefits of SCADA System",
      tiles: [
        {
          description: "Enhanced plant efficiency and overall energy output."
        },
        {
          description: "Reduced downtime and quicker response to faults and anomalies."
        },
        {
          description: "Improved decision-making through data-driven insights and performance predictions."
        },
        {
          description: "Increased return on investment (ROI) by maximizing the output of existing assets."
        },
        {
          description: "Streamlined plant maintenance and operation, resulting in cost savings."
        }
      ]
    },
    {
      title: "Conclusion",
      description: "CODE21's SCADA system, developed using React.JS, has proven to be a game-changer for Electrum Induce S.A in managing large-scale photovoltaic plants. By providing real-time monitoring, data visualization, and predictive analytics, the system empowers Electrum Induce S.A to optimize plant performance, reduce downtime, and make informed decisions for a sustainable energy future.",
      image: WindTurbine,
    }
  ]
}

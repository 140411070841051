import { Arrow } from "./Style";
import SlickArrowPrev from "../../Assets/SlickArrowPrev.svg";

const ArrowLeft = (props) => {
  const {className, onClick} = props;
  return (
    <Arrow className={[className, "prev"].join(" ")} onClick={onClick}>
      <img src={SlickArrowPrev} alt="prev"/>
    </Arrow>
  );
};

export default ArrowLeft;
import {Box, Container, styled} from "@mui/material";

export const CaseStudiesWrapper = styled(Box)(({theme}) => ({
  padding: theme.spacing(10, 0),

  "& .slick-slide > div": {
    height: "100%",
    borderRadius: "15px",
    backgroundColor: theme.palette.white,
    padding: theme.spacing(4, 0),
    color: theme.palette.gray,
    textAlign: "center",

    "& p": {
      margin: theme.spacing(3, 0, 4),
    },

    "& h6": {
      fontWeight: 600,
    },

    "& span": {
      color: theme.palette.lightGray,
    },
  },
}));

export const ContainerWrapper = styled(Container, {
    shouldForwardProp: (prop) => prop !== "isList"
  }
)(({theme, isList}) => ({
  background: isList ? theme.palette.lightPurpleBackground : theme.palette.white,
}));
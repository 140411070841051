import {Box, styled, Typography, Grid} from "@mui/material";

const validation = {
  shouldForwardProp: (prop) => !["areTilesWithoutTitles", "number"].includes(prop)
}
export const SmallTileWithTitle = styled(Box)(({theme}) => (({theme}) => ({
  padding: theme.spacing(2),

  "p": {
    marginTop: theme.spacing(2),
    textAlign: "justify"
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4),
  }
})));

export const SmallTileWithoutTitle = styled(Box, validation)(({theme, number }) => ({
  padding: theme.spacing(4),
  boxShadow: "-1px 0px 20px 0 rgb(223 223 223 / 35%)",
  position: "relative",
  overflow: "hidden",
  height: "100%",
  display: "flex",
  alignItems: "center",

  "&::after": {
    content: `"${number}"`,
    position: "absolute",
    top: 0,
    right: number % 2 === 0 ? 0 : "auto",
    left: number % 2 === 0 ? "auto" : 0,
    fontSize: theme.spacing(24),
fontWeight: 600,
    color: theme.palette.violetPrez,
    opacity: 0.08,
    transform: number % 2 === 0 ? "translate(10%, -20%)" : "translate(0%, -20%)",
  },

  [theme.breakpoints.up("sm")]: {
    "&::after": {
      transform: "translate(20%, -5%)",
      left: "auto",
      right: 0,
    }
  },

  [theme.breakpoints.up("md")]: {
    "&::after": {
      transform: "translate(10%, -15%)",
    }
  },

  [theme.breakpoints.up("lg")]: {
    textAlign: "center",
    padding: theme.spacing(2),

    "&::after": {
      transform: "translate(20%, -5%)",
    }
  }
}));

export const Header = styled(Typography)(({theme}) => ({
  textAlign: "center",
  marginBottom: theme.spacing(4)
}));

export const GridWrapper = styled(Grid, validation)(({theme, areTilesWithoutTitles}) => ({
  rowGap: theme.spacing(2),
  columnGap:theme.spacing(areTilesWithoutTitles ? 4 : 0),

  [theme.breakpoints.up("md")]: {
    rowGap: theme.spacing(4),
  }
}));
import { styled } from "@mui/system";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

export const LogoContainer = styled(Link)(() => ({
  width: "120px",
  height: "40px",
  cursor: "pointer",

  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

export const Sidebar = styled(Stack)(({ theme }) => ({
  width: "32px",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "space-between",

  "& span": {
    width: "100%",
    height: "4px",
    borderRadius: "10px",
    backgroundColor: theme.palette.darkBlue,
  },

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export const Navigation = styled(Stack)(({ theme }) => ({
  display: "none",
  margin: theme.spacing(0, "auto"),

  "& a": {
    display: "flex",
    alignItems: "center",
    color: theme.palette.darkBlue,
    fontSize: "18px",
    padding: theme.spacing(1.25, 1),
    textTransform: "capitalize",

    "& img": {
      display: "block",
      marginLeft: "10px",
      width: "15px",
    },
  },

  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export const ContactButton = styled(Button)(({ theme }) => ({
  display: "none",
  padding: theme.spacing(1.5, 4),
  color: theme.palette.gray,
  backgroundColor: theme.palette.white,
  fontWeight: 400,
  fontSize: "18px",
  boxShadow: theme.customShadows.navbarButton,
  borderRadius: "10px",
  textTransform: "capitalize",

  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

import { styled } from "@mui/system";
import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";

export const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.darkBlue,
  color: theme.palette.white,
  padding: theme.spacing(5, 0),
}));

export const LogoContainer = styled(Link)(() => ({
  display: "inline-block",
  width: "150px",
  height: "40px",

  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

export const Navigation = styled(Stack)(({ theme }) => ({
  "& a": {
    color: theme.palette.white,
  },
}));

export const Socials = styled(Stack)(({ theme }) => ({
  "& a": {
    width: "48px",
    height: "48px",
    borderRadius: "100%",
    backgroundColor: theme.palette.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create(["all"]),

    "&:hover": {
      transform: "scale(1.1)",
    },

    "& img": {
      width: "24px",
      height: "24px",
      objectFit: "contain",
    },
  },
}));

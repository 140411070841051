import React, {useState} from "react";
import {ControllerWrapper, ErrorMessage, MainBox, Message, Socials} from "./Style";
import {Container, Stack, Typography} from "@mui/material";
import {MainButton} from "../../../../Components/Common";
import {FacebookIcon, LinkedInIcon} from "../../../../Shared/assets";
import {Link} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import emailjs from "@emailjs/browser";

const formSendStateInitialValues = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  message: "",
}

const DropMessage = () => {
  const {
    handleSubmit,
    control,
    formState: {errors}
  } = useForm();
  const [formSendState, setFormSendState] = useState(formSendStateInitialValues);
  const onSubmit = (data) => {
    setFormSendState({
      ...formSendStateInitialValues,
      isLoading: true,
    });
    emailjs.send(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      data,
      process.env.REACT_APP_EMAIL_PUBLIC_KEY
    ).then((data) => {
      const isSuccess = data.status === 200;
      setFormSendState({
        isSuccess: isSuccess,
        isFailure: !isSuccess,
        message: isSuccess ? "Message sent successfully!" : "Something went wrong, please try again later.",
        isLoading: false,
      });
    }).catch(() => {
      setFormSendState({
        isSuccess: false,
        isFailure: true,
        message: "Something went wrong, please try again later.",
        isLoading: false,
      })
    });
  }

  return (
    <MainBox id="contactUs">
      <Container>
        <Typography component="h2" variant="h2">
          Drop a message
        </Typography>
        <Stack
          className="content"
          direction="row"
          alignItems="flex-start"
          justifyContent={{xs: "center", md: "flex-start"}}
          spacing={15}
        >
          <Stack alignItems="flex-end" spacing={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ControllerWrapper>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{required: "Name is required"}}
                  render={({field}) => (
                    <input type="text" placeholder="Enter Name" {...field} />
                  )}
                />
                {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
              </ControllerWrapper>
              <ControllerWrapper>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  rules={{required: "Phone Number is required"}}
                  render={({field}) => (
                    <input type="text" placeholder="Enter Phone Number" {...field} />
                  )}
                />
                {errors.phone && <ErrorMessage>{errors.phone.message}</ErrorMessage>}
              </ControllerWrapper>
              <ControllerWrapper>
                <Controller
                  name="message"
                  control={control}
                  defaultValue=""
                  rules={{required: "Message cannot be empty"}}
                  render={({field}) => (
                    <textarea placeholder="Enter Message" {...field} />
                  )}
                />
                {errors.message && <ErrorMessage>{errors.message.message}</ErrorMessage>}
              </ControllerWrapper>
              <Stack
                className="content"
                direction={{xs: "column-reverse", md: "row"}}
                alignItems="center"
                justifyContent={{xs: "center", md: "space-between"}}
                spacing={2}
              >
                {!formSendState.message && <Message>&nbsp;</Message>}
                {formSendState.isSuccess && <Message>{formSendState.message}</Message>}
                {formSendState.isFailure && <ErrorMessage>{formSendState.message}</ErrorMessage>}
                <MainButton type="submit" disabled={formSendState.isLoading}>Submit</MainButton>
              </Stack>
            </form>
          </Stack>

          <Stack spacing={5}>
            <Typography component="h4" variant="h4">
              Address
            </Typography>
            <Stack spacing={1}>
              <Typography component="p" variant="body1">
                ul. Abrahama 1A
              </Typography>
              <Typography component="p" variant="body1">
                80-307 Gdansk
              </Typography>
              <Typography component="p" variant="body1">
                Poland
              </Typography>
            </Stack>

            <Socials direction="row" alignItems="center" spacing={2}>
              <Link to="https://www.linkedin.com/company/code21io" rel="noopener noreferrer" target="_blank">
                <img src={LinkedInIcon} alt="linkedin"/>
              </Link>
              <Link to="https://www.facebook.com/c21io" rel="noopener noreferrer" target="_blank">
                <img src={FacebookIcon} alt="facebook"/>
              </Link>
            </Socials>
          </Stack>
        </Stack>
      </Container>
    </MainBox>
  );
};

export default DropMessage;

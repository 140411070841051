import React from "react";
import { MainContainer, LogoContainer, Navigation, Socials } from "./Style";
import { Container, Stack } from "@mui/material";
import {
  FacebookIcon,
  FooterLogo,
  LinkedInIcon,
  TwitterIcon,
} from "../../Shared/assets";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <MainContainer>
      <Container>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={5}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <LogoContainer to="/">
            <img src={FooterLogo} alt="code21" />
          </LogoContainer>
          <Navigation spacing={1}>
            <Link to="/">code21.io</Link>
            <Link to="/">ul. Abrahama 1A</Link>
            <Link to="/">80-307 Gdansk</Link>
            <Link to="/">Poland</Link>
          </Navigation>

          <Stack
            direction={{
              xs: "column",
              sm: "row-reverse",
              md: "column-reverse",
            }}
            justifyContent="space-between"
            spacing={{ xs: 5, sm: 15, md: 3 }}
          >
            <Socials direction="row" alignItems="center" spacing={2}>
              <Link to="/">
                <img src={LinkedInIcon} alt="linkedin" />
              </Link>
              <Link to="/">
                <img src={FacebookIcon} alt="facebook" />
              </Link>
              <Link to="/">
                <img src={TwitterIcon} alt="twitter" />
              </Link>
            </Socials>

            <Navigation spacing={1}>
              <Link to="/">SERVICES</Link>
              <Link to="/">ABOUT US</Link>
            </Navigation>
          </Stack>
        </Stack>
      </Container>
    </MainContainer>
  );
};

export default Footer;

import React from "react";
import { Container, Typography, Stack } from "@mui/material";
import { AboutUsSection } from "./Style";
import { MainButton } from "../../../../Components/Common";
import { AboutUs } from "../../../../Shared/assets";

const AboutUsComponent = () => {
  return (
    <AboutUsSection>
      <Container>
        <Typography component="h2" variant="h2">
          About us
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row-reverse" }}
          spacing={{ xs: 4 }}
          justifyContent="flex-end"
          alignItems="center"
        >
          <img src={AboutUs} alt="about us" />
          <div>
            <Typography component="p" variant="body1">
              Welcome to Code21, a leading software development company that is
              part of the Hand2Band Group. Our team of talented and experienced
              software developers, designers, and project managers are committed
              to providing exceptional software solutions to our clients across
              a wide range of industries.
            </Typography>
          </div>
        </Stack>
      </Container>
    </AboutUsSection>
  );
};

export default AboutUsComponent;

import React from "react";
import { Container, Stack } from "@mui/material";
import { LogoContainer, Sidebar, Navigation, ContactButton } from "./Style";
import { DownIcon, Logo } from "../../Shared/assets";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <Container>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 2, md: 4 }}
        sx={{ py: 3 }}
      >
        <LogoContainer to="/">
          <img src={Logo} alt="code21" />
        </LogoContainer>

        <Navigation direction="row" alignItems="center" spacing={3}>
          <a href="#services">services</a>
          <a href="#caseStudies">
            case studies <img src={DownIcon} alt="arrow" />
          </a>
          <Link to="">
            careers <img src={DownIcon} alt="arrow" />
          </Link>
        </Navigation>

        <ContactButton>Contact us</ContactButton>

        <Sidebar spacing={0.5}>
          <span></span>
          <span></span>
          <span></span>
        </Sidebar>
      </Stack>
    </Container>
  );
};

export default Navbar;

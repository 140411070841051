import { TextTileWrapper, Header, Description }from "./Style";

const TextTile = ({ title, description, align, isHero = false }) => {
  const isAlignRight = align === "right";
  const currentAlign = align ? align : isAlignRight ? "flex-end" : "flex-start";
  const headerType = isHero ? "h1" : "h2";

  return (
    <TextTileWrapper align={currentAlign} isAlignRight={isAlignRight} isHero={isHero}>
      <Header component={headerType} variant={headerType} isHero={isHero}>{title}</Header>
      <Description component="p" variant="body1" isHero={isHero}>{description}</Description>
    </TextTileWrapper>
  );
}

export default TextTile;

import { styled } from "@mui/material";

export const MainBox = styled("section")(({ theme }) => ({
  backgroundColor: theme.palette.lightPurpleBackground,

  "& > div > p": {
    color: theme.palette.gray,
    textAlign: "center",
    margin: theme.spacing(0, "auto", 5),
    maxWidth: "700px",
  },

  "& .item": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.darkBlue,

    "& h3": {
      fontSize: "34px",
      marginBottom: theme.spacing(2),
    },

    "& p": {
      maxWidth: "300px",
      margin: "0 auto",
    },
  },
}));

import { styled, Box } from "@mui/material";

export const MyHome = styled(Box)(({ theme }) => ({
  "& section": {
    padding: theme.spacing(10, 0),
  },

  "& h2": {
    width: "fit-content",
    margin: theme.spacing(0, "auto", 5),
    paddingBottom: theme.spacing(3),
    color: theme.palette.gray,
    textAlign: "center",
    position: "relative",

    "&::after": {
      content: '""',
      position: "absolute",
      top: "100%",
      left: "25%",
      width: "50%",
      height: "4px",
      borderRadius: "10px",
      backgroundColor: theme.palette.violetPrez,
    },
  },
}));

import {Container, Grid} from "@mui/material";
import Slider from "react-slick";
import {CaseStudiesWrapper, ContainerWrapper} from "./Style";
import {TextTile, TextWithImage, TilesGrid} from "./Components";
import {useParams} from "react-router-dom";

import {scada} from "./caseStudiesData";

const getCaseStudiesData = (name) => {
  switch (name) {
    case "scada":
      return scada;
    default:
      return null;
  }
}

const CaseStudies = () => {
  const {name} = useParams();
  const caseStudies = getCaseStudiesData(name);

  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    arrows: false,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  }

  if (!caseStudies) return null;

  return (
    <Container disableGutters maxWidth={false}>
      <CaseStudiesWrapper>
        <Container
          disableGutters
          sx={{
            padding: "0 1.5rem 6rem"
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} md={4}>
              <TextTile
                align="left"
                description={caseStudies.description}
                title={caseStudies.title}
                isHero
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Slider {...settings}>
                {caseStudies.carousel.map((item, index) => (
                  <img src={item} alt="Carousel item" key={index}/>
                ))}
              </Slider>
            </Grid>
          </Grid>
        </Container>
        {caseStudies.sections.map((section, sectionIndex) => {
          const {title, description, image, tiles} = section;

          const nonListElements = caseStudies.sections.filter((section) => section.description);
          const currentElement = nonListElements.findIndex((section) => section.title === title);
          const textAlign = currentElement % 2 === 0 ? "left" : "right";

          return (
            <ContainerWrapper
              disableGutters
              maxWidth={false}
              key={sectionIndex}
              sx={{
                padding: "6rem 0"
              }}
              isList={!!tiles}
            >
              <Container>
                {tiles?.length ? (
                  <TilesGrid
                    title={title}
                    tiles={tiles}
                  />
                ) : (
                  <TextWithImage
                    title={title}
                    description={description}
                    image={image}
                    textAlign={textAlign}
                  />
                )}
              </Container>
            </ContainerWrapper>
          );
        })}
      </CaseStudiesWrapper>
    </Container>
  );
}

export default CaseStudies;
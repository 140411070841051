import {Grid, Typography} from "@mui/material";
import {GridWrapper, Header, SmallTileWithTitle, SmallTileWithoutTitle} from "./Style";

const TilesGrid = ({title, tiles}) => {
  const areTilesWithoutTitles = tiles.some(tile => !tile.title);

  const sizes = {
    xs: areTilesWithoutTitles ? 12 : 12,
    sm: areTilesWithoutTitles ? 4 : 6,
    md: areTilesWithoutTitles ? 4 : 4,
    lg: areTilesWithoutTitles ? 2 : 4,
  }

  const TileComponent = areTilesWithoutTitles ? SmallTileWithoutTitle : SmallTileWithTitle;

  return (
    <>
      <Header component="h2" variant="h2">{title}</Header>
      <GridWrapper
        container
        spacing={0}
        margin={0}
        justifyContent="center"
        areTilesWithoutTitles={areTilesWithoutTitles}
      >
        {tiles.map((tile, index) => {
          const { title, description } = tile;
          return (
            <Grid {...sizes} item key={index}>
              <TileComponent number={index + 1}>
                {title && <Typography component="h3" variant="h3">{tile.title}</Typography>}
                {description && <Typography component="p" variant="body1">{tile.description}</Typography>}
              </TileComponent>
            </Grid>
          )
        })}
      </GridWrapper>
    </>
  )
}

export default TilesGrid;
import {Box, styled} from "@mui/system";
import {Typography} from "@mui/material";

const allowedProps = ["isHero", "isAlignRight", "align"];
const validation = {
  shouldForwardProp: (prop) => !allowedProps.includes(prop)
}
export const TextTileWrapper = styled(Box, validation)(({theme, align, isAlignRight, isHero}) => ({
  borderRadius: 6,
  display: "flex",
  flexDirection: "column",
  justifyContent: align,
  alignItems: align,

  "p": {
    textAlign: "justify"
  },

  [theme.breakpoints.up("md")]: {
    padding: isHero ? theme.spacing(0, 4, 0, 0) : theme.spacing(0, isAlignRight ? 4 : 0, 0, isAlignRight ? 0 : 4),
  },

  [theme.breakpoints.up("lg")]: {
    padding: isHero ? theme.spacing(0, 6, 0, 2) : theme.spacing(0, isAlignRight ? 8 : 0, 0, isAlignRight ? 0 : 8),
  }
}));

export const Header = styled(Typography, validation)(({theme, isHero}) => ({
  position: "relative",
  "&::after": {
    content: isHero ? '""' : "none",
    position: "absolute",
    top: "100%",
    left: "0",
    width: "50%",
    height: "4px",
    borderRadius: "10px",
    backgroundColor: theme.palette.violetPrez,
  }
}));

export const Description = styled(Typography, validation)(({theme, isHero}) => ({
  marginTop: isHero ? theme.spacing(4) : theme.spacing(2),
}));
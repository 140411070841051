import { Stack, styled } from "@mui/material";

export const LocationSection = styled("section")(({ theme }) => ({
  "& .map": {
    width: "100%",
    height: "450px",
    objectFit: "cover",
  },

  [theme.breakpoints.up("md")]: {
    "& h2": {
      marginLeft: 0,
    },
  },
}));

export const Content = styled(Stack)(({ theme }) => ({
  display: "none",
  position: "relative",
  overflow: "hidden",
  marginBottom: theme.spacing(5),

  "& > div": {
    maxWidth: "500px",
  },

  "& .mainImage": {
    width: "350px",
    height: "360px",
    objectFit: "contain",
    display: "block",
    margin: theme.spacing(0, "auto"),
  },

  "& .icon": {
    width: "36px",
    height: "36px",
    objectFit: "contain",
  },

  "& h4, & p": {
    color: "#4A4A4A",
  },

  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },

  [theme.breakpoints.up("md")]: {
    paddingBottom: theme.spacing(12.5),
    marginBottom: theme.spacing(10),
    overflow: "visible",

    "& > div": {
      maxWidth: "1200px",
    },

    "& .mainImage": {
      width: "400px",
      height: "284px",
      position: "absolute",
      left: "75%",
      top: "30%",
      transform: "translate(-50%, -50%)",
    },

    "& p": {
      maxWidth: "500px",
    },
  },

  [theme.breakpoints.up("lg")]: {
    "& .mainImage": {
      left: "80%",
      width: "450px",
      height: "334px",
    },
  },
}));

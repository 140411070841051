import { styled } from "@mui/material";

export const MainBox = styled("section")(({ theme }) => ({
  "& > div": {
    maxWidth: "500px",
    position: "relative",
  },

  "& img": {
    width: "300px",
    height: "225px",
    objectFit: "contain",
    display: "block",
    margin: theme.spacing(0, "auto"),
  },

  "& p": {
    color: theme.palette.gray,
    marginBottom: theme.spacing(4),
  },

  "& button": {
    display: "block",
    margin: theme.spacing(0, "auto"),
  },

  [theme.breakpoints.up("sm")]: {
    "& img": {
      width: "400px",
      height: "305px",
    },
  },

  [theme.breakpoints.up("md")]: {
    "& h2": {
      marginRight: 0,
    },

    "& > div": {
      maxWidth: "1200px",
      minHeight: "284px",
    },

    "& img": {
      width: "400px",
      height: "284px",
      position: "absolute",
      left: "0",
      top: "-20%",
    },

    "& p": {
      width: "346px",
    },

    "& button": {
      margin: 0,
    },
  },

  [theme.breakpoints.up("lg")]: {
    "& > div": {
      minHeight: "334px",
    },

    "& img": {
      width: "450px",
      height: "334px",
    },

    "& p": {
      width: "508px",
    },
  },
}));

import { Box, Stack, styled } from "@mui/material";

export const MainBox = styled("section")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    "& h2": {
      display: "block",
    },
  },
}));

export const MobileSection = styled(Stack)(({ theme }) => ({
  "& img": {
    width: "133px",
    height: "40px",
    objectFit: "contain",
  },

  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

export const Item = styled(Stack)(({ theme }) => ({
  color: theme.palette.darkBlue,

  "& h3": {
    marginBottom: theme.spacing(0.5),
    fontWeight: 500,
  },

  "& h4": {
    marginBottom: theme.spacing(4),
    fontWeight: 500,
  },

  "& p": {
    textAlign: "justify",
    marginBottom: theme.spacing(5),
  },

  "& button": {
    backgroundColor: theme.palette.white,
    border: `2px solid ${theme.palette.darkBlue}`,
    color: theme.palette.darkBlue,

    "&:hover": {
      backgroundColor: theme.palette.darkBlue,
      color: theme.palette.white,
    },
  },

  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

export const ProjectVisual = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "400px",
  padding: theme.spacing(4),
  borderRadius: "16px",
  backgroundColor: "#F9F9F9",
  boxShadow: "10px 10px 30px 0 rgba(0, 0, 0, 0.1)",
  color: "rgba(0, 0, 0, 0.2)",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",

  "& img": {
    height: "100%",
    aspectRatio: "16/9"
  },

  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-start",
    width: "400px",
    minWidth: "400px",
    height: "600px",
  },

  [theme.breakpoints.up("lg")]: {
    width: "500px",
    minWidth: "500px",
  },
}));

import React from "react";
import { LocationSection, Content } from "./Style";
import { Container, Stack, Typography } from "@mui/material";
import {
  LocationIcon,
  LocationImage,
  LocationMap,
} from "../../../../Shared/assets";

const LocationComponent = () => {
  return (
    <LocationSection>
      <Container>
        <Typography component="h2" variant="h2">
          Location
        </Typography>
        <Content
          direction={{ sm: "column", md: "row-reverse" }}
          spacing={{ xs: 4 }}
          justifyContent="flex-end"
          alignItems="center"
        >
          <img className="mainImage" src={LocationImage} alt="location" />
          <div>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ mb: 3 }}
            >
              <img className="icon" src={LocationIcon} alt="location" />
              <Typography component="h4" variant="h4">
                Gdansk, Poland
              </Typography>
            </Stack>
            <Typography component="p" variant="body1">
              • Biggest BPO hub in northern Poland - ABSL 2021 report <br />
              • Located in the middle of Europe (EST -6h +6h HKT)
              <br />• Office 15 minutes from the airport
            </Typography>
          </div>
        </Content>
      </Container>
      <img className="map" src={LocationMap} alt="map" />
    </LocationSection>
  );
};

export default LocationComponent;

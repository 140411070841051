import React from "react";
import { Container, Typography, Stack } from "@mui/material";
import { WhatWeDoSection } from "./Style";
import { WhatWeDo1, WhatWeDo2, WhatWeDo3 } from "../../../../Shared/assets";

const WhatWeDoArray = [
  {
    src: WhatWeDo1,
    title: "Nearshoring",
    desc: "We provide external IT resources for our partners in EU/US and within Poland. We specialize in Web technologies, cloud solutions and manual/automated testing.",
  },
  {
    src: WhatWeDo2,
    title: "Web Development",
    desc: "We specialize in web solutions, on both backend and frontend. For our customers we have delivered solutions in Angular.JS, React.JS, Node.JS and Python in AWS/Azure and Google Cloud.",
  },
  {
    src: WhatWeDo3,
    title: "Product Development",
    desc: "Our talented analysts and UX/UI designers will guide you with conceptual development of your product. We work with Figma/Adobe XD to create clickable prototypes.",
  },
];

const WhatWeDoComponent = () => {
  return (
    <Container id="services">
      <WhatWeDoSection>
        <Typography component="h2" variant="h2">
          What we do?
        </Typography>
        <Typography component="p" variant="body1">
          Code21, established in 2021 in Gdansk, pioneers tailored software solutions, fusing cutting-edge technology and creative prowess to empower businesses for success and growth.
        </Typography>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          alignItems="center"
          spacing={5}
        >
          {WhatWeDoArray?.map((item, index) => (
            <Stack
              direction={{ xs: "column", sm: "row", lg: "column" }}
              spacing={{ xs: 3, sm: 5, lg: 4 }}
              alignItems="center"
              className="singleItem"
              key={index}
            >
              <img src={item.src} alt={item.title} />
              <div>
                <Typography component="h4" variant="h4">
                  {item.title}
                </Typography>
                <Typography component="p" variant="body1" align="justify">
                  {item.desc}
                </Typography>
              </div>
            </Stack>
          ))}
        </Stack>
      </WhatWeDoSection>
    </Container>
  );
};

export default WhatWeDoComponent;

import { createTheme } from "@mui/material";

let theme = createTheme({});

theme = createTheme(theme, {
  palette: {
    lightGray: "#A6A6A6",
    gray: "#484848",
    darkBlue: "#263238",
    lightPurpleBackground: "#F6F7FF",
    violetPrez: "#AF73BC",
    white: "#fff",
    failure: "#da5252",
    success: "#7fda52",
  },

  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        // disableRipple: true, // No more ripple, on the whole application 💣!
        disableRipple: false, // enabled ripple, on the whole application 💣!
      },

      styleOverrides: {
        root: () => ({
          "&:hover": {
            backgroundColor: "initial",
          },
        }),
      },
    },

    MuiContainer: {
      defaultProps: {
        maxWidth: "lg",
      },
    },

    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        // most basic recommended timing
        primary: 300,
        // this is to be used in complex animations
        complex: 375,
        // recommended when something is entering screen
        enteringScreen: 225,
        // recommended when something is leaving screen
        leavingScreen: 195,
      },
      easing: {
        // This is the most common easing curve.
        easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
        // Objects enter the screen at full velocity from off-screen and
        // slowly decelerate to a resting point.
        easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
        // Objects leave the screen at full velocity. They do not decelerate when off-screen.
        easeIn: "cubic-bezier(0.4, 0, 1, 1)",
        // The sharp curve is used by objects that may return to the screen at any time.
        sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
      },
    },
  },

  customShadows: {
    navbarButton: "0 10px 20px 0 rgba(166, 166, 166, 0.5)",
    whatWeDoItem: "-32px 32px 100px 0 rgba(222, 221, 250, 0.8)",
  },

  customBorderRadius: {},

  typography: {
    fontFamily: "'Poppins', sans-serif",

    h1: {
      fontSize: "50px",
      fontWeight: 500,
    },
    h2: {
      fontSize: "41px",
      fontWeight: 600,

      [theme.breakpoints.down("lg")]: {
        fontSize: "28px",
      },

      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
      },
    },
    h3: {
      fontSize: "28px",
      fontWeight: 500,

      [theme.breakpoints.down("lg")]: {
        fontSize: "34px",
      },

      [theme.breakpoints.down("md")]: {
        fontSize: "28px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    h4: {
      fontSize: "24px",
      fontWeight: 500,
    },
    h5: {
      fontSize: "24px",
      fontWeight: 400,
    },
    h6: {
      fontSize: "20px",
      fontWeight: 400,

      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
    },
    button: {
      fontSize: "16px",
      fontWeight: 600,
    },
  },

  animation: "skeleton 1s ease infinite alternate",
});

export default theme;

import { Box, styled } from "@mui/material";

export const MainBox = styled("section")(({ theme }) => ({
  backgroundColor: theme.palette.violetPrez,
  overflow: "hidden",

  "& h2": {
    color: theme.palette.white,

    "&::after": {
      backgroundColor: theme.palette.white,
    },
  },

  "& .MuiContainer-root > p": {
    display: "none",
    color: theme.palette.white,
    textAlign: "center",
    maxWidth: "600px",
    margin: theme.spacing(0, "auto", 10),
  },

  "& .MuiGrid-item p": {
    textAlign: "center",
    color: theme.palette.white,
    maxWidth: "140px",
  },

  "& .MuiGrid-item:nth-of-type(2) > div > div::after, .MuiGrid-item:nth-of-type(4) > div > div::after, .MuiGrid-item:nth-of-type(6) > div > div::after":
    {
      display: "none",
    },

  [theme.breakpoints.up("sm")]: {
    "& .MuiContainer-root > p": {
      display: "block",
    },

    "& .MuiGrid-item:nth-of-type(2) > div > div::after, .MuiGrid-item:nth-of-type(4) > div > div::after, .MuiGrid-item:nth-of-type(6) > div > div::after":
      {
        display: "block",
      },

    "& .MuiGrid-item:nth-of-type(3) > div > div::after, .MuiGrid-item:nth-of-type(6) > div > div::after":
      {
        display: "none",
      },
  },

  [theme.breakpoints.up("lg")]: {
    "& .MuiGrid-item:nth-of-type(3) > div > div::after, .MuiGrid-item:nth-of-type(6) > div > div::after":
      {
        display: "block",
      },

    "& .MuiGrid-item:nth-of-type(6) > div > div::after": {
      display: "none",
    },
  },
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  width: "72px",
  height: "72px",
  borderRadius: "15px",
  backgroundColor: theme.palette.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: "rotate(45deg)",
  position: "relative",

  "&::after": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    top: "-62px",
    left: "134px",
    transform: "translate(-50%, -50%) rotate(-45deg)",
    backgroundColor: theme.palette.white,
    width: "250px",
    height: "3px",
  },

  "& img": {
    width: "32px",
    height: "32px",
    objectFit: "contain",
    transform: "rotate(-45deg)",
  },

  [theme.breakpoints.up("sm")]: {
    "&::after": {
      width: "190px",
    },
  },

  "@media screen and (min-width: 800px)": {
    "&::after": {
      width: "320px",
    },
  },

  "@media screen and (min-width: 1000px)": {
    "&::after": {
      width: "350px",
    },
  },

  "@media screen and (min-width: 1100px)": {
    "&::after": {
      top: "-76px",
      left: "148px",
      width: "400px",
    },
  },

  [theme.breakpoints.up("lg")]: {
    "&::after": {
      top: "-62px",
      left: "134px",
      width: "200px",
    },
  },
}));
